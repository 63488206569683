<template>
    <div class="c-map-page">
        <panzoom-map
            v-if="mapHeight && mapWidth"
            :height="mapHeight"
            :width="mapWidth"
            :allowZoom="true"
            :start-center="startCenterPos"
            :minZoom="zoomRange.min > 0 ? zoomRange.min : -0"
            :maxZoom="zoomRange.max > 0 ? zoomRange.max : Infinity"
            :startZoom="pageData.StartZoom"
            :geoData="geoData"
            @scaleChanged="onScaleChanged">
                <image-entry
              :localPageData="pageData"
              v-for="(entry, index) in mapImageEntries"
              v-show="index <= layer"
              :entryData="entry"
              :key="entry.ID"
              mode="map">
              </image-entry>
        </panzoom-map>
        <!-- audio player can't be slotted (see dynaimcpage->renderaudioplayer()) without changing how pagedata for xpmap is being delivered from backend, checkout xppanoramapage for section slotting reference -->
        <div v-if="$xp.navigation.isAudioSynced">
              <audio-player
                :source="$xp.settings.baseUrl + 'xpedeo/' + pageData.Audio.Url"
                :binaryId="pageData.Audio.BinaryID"
                :pageId="pageData.ID"
                :audioSync="pageData.AudioSync"
                :autoPlay="$xp.settings.autoPlayPageAudio"
                :lipSyncUrl="(pageData.Class === 'XpVideoSync') ? pageData.lipSyncUrl : null"></audio-player>
        </div>
        <div class="c-map-page--preload" v-if="preload">
            <img v-for="(entry) in mapImageEntries.filter((item, index) => index != 0)" :key="entry.ID" :src="$xp.settings.baseUrl + 'xpedeo/' + entry.Image.Url" alt="">
        </div>

    </div>
</template>

<script>
import pageMixin from '../../mixins/page'
import PanzoomMap from '../PanzoomMap.vue'
import ImageEntry from '../entries/ImageEntry.vue'
import AudioPlayer from '../AudioPlayer.vue'
export default {
  mixins: [pageMixin],
  data () {
    return {
      mapImageEntries: [],
      mapWidth: null,
      mapHeight: null,
      layersCount: 0,
      layer: 0,
      layersMinZoomLevel: [0],
      preload: false
    }
  },
  computed: {
    startCenterPos () {
      return this.pageData.StartCenterPos
    },
    zoomRange () {
      if (this.pageData.ZoomRange) { return this.pageData.ZoomRange } else return { max: 100, min: 100 }
    },
    geoData () {
      if (this.pageData.Coords) {
        return {
          topLeft: this.pageData.Coords.topLeft,
          bottomRight: this.pageData.Coords.bottomRight,
          diffNorth: this.pageData.DiffNorth,
          system: this.pageData.System
        }
      }
      return null
    }
  },
  created () {
    if (this.pageData.Sections.length > 0) {
      const entries = this.pageData.Sections[0].entries.filter(e => e.Class === 'Image')
      if (entries.length > 0) {
        this.mapImageEntries = entries
        this.layersCount = entries.length
        this.mapWidth = entries[0].Width
        this.mapHeight = entries[0].Height
        this.layersMinZoomLevel = [0]
        const zoomRange = this.zoomRange.max - this.zoomRange.min
        if (zoomRange > 0) {
          for (let i = 1; i <= this.layersCount - 1; i++) {
            this.layersMinZoomLevel.push(this.zoomRange.min + i * (zoomRange / this.layersCount))
          }
        }
      } else {
        console.warn('XpMapPage does not have any image entries.')
      }
    } else {
      console.warn('XpMapPage does not have any section.')
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.preload = true
    })
  },
  methods: {
    onScaleChanged (scale) {
      console.log(scale)
      if (this.layersCount > 1) {
        for (let i = this.layersCount; i >= 0; i--) {
          if (this.layersMinZoomLevel[i] <= scale) {
            this.switchLayer(i)
            break
          }
        }
      }
    },
    switchLayer (newLayer) {
      if (newLayer !== this.layer) {
        this.layer = newLayer
      }
    }
  },
  components: {
    PanzoomMap,
    ImageEntry,
    AudioPlayer
  }
}
</script>
